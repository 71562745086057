@import "../sass/variables";

.gc-alphabet-chart {
    border-left: 1px solid $color-light;
    float: left;
    margin-bottom: $padding-l;
    max-width: 394px;
}

.gc-chart-cell {
    background: $color-light;
    border-top: 1px solid $color-white;
    border-left: 1px solid $color-white;
    border-right: 1px solid $color-medium;
    border-bottom: 1px solid $color-medium;
    float: left;
    text-align: center;
}

.gc-chart-letter,
.gc-chart-value {
    min-width: 2em;
    padding: $padding-s;
}
