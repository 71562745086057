@import "../sass/variables";

.gc-details-header {
    padding: $padding-l $padding-l 0 0;
}

.gc-details-heading {
    float: left;
    margin: 0;
    padding-bottom: $padding-s;
}

.gc-details-values {
    float: right;
}

.gc-details-text-output {
    padding: $padding-m 0;
}

.gc-details-options {
    padding-bottom: $padding-l;
}

.gc-details-empty-message {
    text-align: left;
}

.gc-view-details {
    .gc-section-heading {
        margin: $padding-l 0 $padding-s;
    }

    .gc-with-numbers {
        @extend %gradient-gray;
        border-bottom: 1px solid $color-medium;
        border-right: 1px solid $color-medium;
        margin-bottom: $padding-m;
        padding: $padding-s;

        &:last-child {
            margin: 0;
        }
    }
}
