@import "../sass/variables";

.gc-with-numbers {
    align-items: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 0;

    .gc-content {
        flex: 1;
        margin: 0;
        padding: $padding-s $padding-m;
    }

    .gc-meta {
        display: flex;
        flex-direction: row;
    }

    .gc-values {
        align-items: center;
        display: flex;
        flex-direction: row;
    }
}

.gc-close-button {
    background: $color-red;
    border: 0;
    border-radius: $border-radius;
    cursor: pointer;
    height: 32px;
    margin: 0 $padding-s;
    opacity: 0.6;
    position: relative;
    width: 32px;

    &:hover {
        opacity: 0.4;
    }

    div {
        color: $color-white;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
    }
}
