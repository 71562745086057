@import "../sass/variables";

.gc-footer {
    color: $color-red;
    font-size: $font-size-s;
    letter-spacing: 0.1em;
    padding: $padding-l 0;
}

.gc-footer-copyright {
    display: block;
    float: left;
    line-height: 16px;
}

.gc-footer-logo {
    display: block;
    float: right;
}
