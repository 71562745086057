@import "../sass/variables";

.gen-modal-header-title {
    float: left;
}

.gen-modal-header-icon {
    cursor: pointer;
    float: right;
    padding-right: $padding-l;
}
