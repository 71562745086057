@import "../sass/variables";

.gc-number-gematria {
    // background-color: $color-green;
    padding: $padding-s $padding-m;
    text-align: right;

    .gc-label {
        color: #fff;
        display: inline-block;
        opacity: 0.6;
        padding-right: 6px;
    }

    .gc-number {
        color: $color-green;
        font-size: $font-size-l;
        font-weight: 600;
    }
}