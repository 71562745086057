@import "sass/variables";
@import "sass/utilities";

// Arrow icon

.gen-icon-arrow-left {
  color: #000;
  position: absolute;
  margin-left: 3px;
  margin-top: 10px;
  width: 16px;
  height: 1px;
  background-color: currentColor;
}

.gen-icon-arrow-left:before {
  content: "";
  position: absolute;
  left: 1px;
  top: -5px;
  width: 10px;
  height: 10px;
  border-top: solid 1px currentColor;
  border-right: solid 1px currentColor;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

// Close icon

.gen-icon-close {
  color: #000;
  position: absolute;
  margin-top: 0;
  margin-left: 0;
  width: 21px;
  height: 21px;
}

.gen-icon-close:before {
  content: "";
  position: absolute;
  top: 10px;
  width: 21px;
  height: 1px;
  background-color: currentColor;
  transform: rotate(-45deg);
}

.gen-icon-close:after {
  content: "";
  position: absolute;
  top: 10px;
  width: 21px;
  height: 1px;
  background-color: currentColor;
  transform: rotate(45deg);
}

// Overrides

.ui.label {
  margin: 1px !important;
}

// App Boilerplate

.gc-main-container {
  margin: 0 auto;
  max-width: $max-width;
  padding: $padding-m;
}

.gc-top-menu {
  padding-bottom: $padding-l;
}

.gc-label {
  font-size: 10px;
  text-transform: uppercase;
}

// ButtonSettings

.gc-button-settings {
  float: right;
  margin-right: 0 !important;
}

// Global styles

.gc-main-container {
  font-size: 14px;
}