@import "../sass/variables";

.gc-word {
    float: left;
    padding-right: $padding-l;
}

.gc-word-value {
    color: $color-value;
    font-size: $font-size-m;
    font-weight: 600;
    text-align: center;
}

.gc-letters {
    border-bottom: 1px solid $color-neutral;
}

.gc-letter {
    float: left;
    min-width: 1.2em;
}

.gc-letter-alpha,
.gc-letter-value {
    display: block;
    padding: 1px;
    text-align: center;
}

.gc-letter-alpha {
    font-size: $font-size-m;
}

.gc-letter-value,
.gc-chart-value {
    color: $color-value;
    font-size: $font-size-s;
}
