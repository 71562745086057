@import "../sass/variables";

.gc-list-item {
    @extend %gradient-gray;
    border-right: 1px solid $color-medium;
    border-bottom: 1px solid $color-medium;
    border-radius: 3px;
    margin-bottom: $padding-m;
    padding: $padding-s;

    .gc-snapshot-container {
        .gc-snapshot-content {
            font-size: $font-size-s;
        }

        .gc-with-numbers {
            border-top: 1px solid $color-medium;
        }

        .gc-values {
            font-size: $font-size-s;
        }

        .gc-number-gematria .gc-number {
            font-weight: 400;
        }
    }
}