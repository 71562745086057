// Common util classes
// (See below for utilities.js specific styles)

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.gc-list {
  margin: 0;
  padding: 0 0 0 $padding-l;

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.gc-list-compact {
  li {
    padding-top: $padding-s;

    &:first-child {
      padding: 0;
    }
  }

  ul li:first-child {
    padding-top: $padding-s;
  }
}

.gc-list-spacious {
  li {
    padding-top: $padding-l;

    &:first-child {
      padding: 0;
    }
  }

  ul li:first-child {
    padding-top: $padding-l;
  }
}

.gc-text-s {
  font-size: $font-size-s;
}

.gc-text-m {
  font-size: $font-size-m;
}

.gc-text-l {
  font-size: $font-size-l;
}

// Utilies.js

.gc-table {
  border-spacing: 0;

  thead {
    font-weight: bold;
  }

  tr {
    border-bottom: 1px solid $color-medium;

    &:last-child {
      border: 0;
    }
  }

  td {
    padding: 5px 10px;
    text-align: center;

    &:first-child {
      border-right: 1px solid $color-medium;
      text-align: right;
    }
  }
}

.gc-table-number-label {
  background-color: $color-medium;
  border-radius: $border-radius 0 0 $border-radius;
}

.gc-table-number {
  background-color: $color-medium;
  border-radius: 0 $border-radius $border-radius 0;
}
