@import "../sass/variables";

.gc-linked-cipher-item {
    cursor: pointer;

    .gc-name {
        color: $color-blue;

        &:hover {
            color: $color-blue-alt;
        }
    }
}