$color-red: #db2828;
$color-blue: #2185d0;
$color-blue-alt: #1e70bf;
$color-orange: #f2711c;
$color-green: #21ba45;
$color-white: #fff;
$color-light: #eee;
$color-medium: #ddd;
$color-smoke: #999;
$color-dark: #1b1c1d;

$border-radius: 4px;
$color-value: $color-green;
$color-neutral: #222;
$font-size-s: 80%;
$font-size-m: 100%;
$font-size-l: 115%;
$font-huge: 150%;
$max-width: 768px;
$padding-s: 0.25em;
$padding-m: 0.5em;
$padding-l: 1em;

%shadow {
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.5);
}

%gradient-gray {
  background: rgb(221,221,221);
background: linear-gradient(0deg, rgba(233,233,233,1) 0%, rgba(238,238,238,1) 100%);
}
