@import "../sass/variables";

.gc-number-reduction {
    // background-color: $color-medium;
    margin-left: -$padding-l;
    padding: 0 $padding-m;
    text-align: right;

    .gc-number-divider {
        color: $color-smoke;
    }

    .gc-label {
        color: #fff;
        display: inline-block;
        opacity: 0.6;
        padding-right: 6px;
    }

    .gc-number {
        color: $color-smoke;
        font-size: $font-size-s;
        font-weight: 300;
    }
}